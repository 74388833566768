import {Outlet} from 'react-router-dom'
import {Content} from './components/Content'

const MasterLayout = () => {
  return (
      <div
			className="d-flex flex-column flex-column-fluid bgi-position-y-center position-x-center bgi-no-repeat bgi-size-cover bgi-attachment-fixed"
			style={{backgroundImage: 'url(/media/img/section-bg.png)'}}>
        <Content>
          <Outlet />
        </Content>
      </div>
  )
}

export {MasterLayout}
