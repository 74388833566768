import {useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {getUserEmpty, register} from '../modules/auth/core/_requests'
import {toAbsoluteUrl} from '../../_metronic/helpers'
import {useAuth} from '../modules/auth/core/Auth'
// import {AuthModel, UserModel} from './_models'

const initialValues = {
  firstname: '',
  lastname: '',
  email: '',
  mobile: '',
  wallet: '',
  amount: '',
  acceptTerms: false,
}                  

const registrationSchema = Yup.object().shape({
  firstname: Yup.string()
    .min(3, 'ชื่อไม่ถูกต้อง')
    .max(50, 'ชื่อไม่ถูกต้อง')
    .required('กรุณากรอกชื่อ'),
  email: Yup.string()
    .email('อีเมลไม่ถูกต้อง')
    .min(3, 'อีเมลไม่ถูกต้อง')
    .max(50, 'อีเมลไม่ถูกต้อง')
    .required('กรุณากรอกอีเมล'),
  lastname: Yup.string()
    .min(3, 'นามสกุลไม่ถูกต้อง')
    .max(50, 'นามสกุลไม่ถูกต้อง')
    .required('กรุณากรอกนามสกุล'),
  mobile: Yup.string()
    .min(10, 'เบอร์โทรไม่ถูกต้อง')
    .max(50, 'เบอร์โทรไม่ถูกต้อง'),
  wallet: Yup.string()
    .min(30, 'Wallet Address ไม่ถูกต้อง')
    .max(50, 'Wallet Address ไม่ถูกต้อง')
    .required('กรุณากรอก Wallet Address'),
  amount: Yup.string()
    .required('กรุณากรอกจำนวนที่ท่านต้องการซื้อ'),
  acceptTerms: Yup.bool().required('กรุณาติ๊กที่ช่องสี่เหลี่ยมเพื่อยอมรับความเสี่ยง'),
})

export function Home() {
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser, currentUser} = useAuth()
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        await register(
          currentUser ? currentUser.id : 0,
          values.email,
          values.firstname,
          values.lastname,
          values.mobile,
          values.wallet,
          values.amount
        )
        const user = getUserEmpty()
        setCurrentUser(user)

      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('ข้อมูลไม่ถูกต้องหรือระบบขัดข้อง กรุณาติดต่อเจ้าหน้าที่')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
      <div className="mb-12">
          <img alt="Logo" src={toAbsoluteUrl('/media/coins/zii-token-logo.svg')} className="h-45px logo" />
      </div>
      <div className="w-lg-600px bg-body rounded shadow-sm p-8 p-lg-15 mx-auto mb-5">
        <div className="w-100">
          <h2 className="fs-1 mb-8">หลักเกณฑ์ในการซื้อโทเคนดิจิทัล “Zii Token”</h2>
          <h3 className="fs-3 mb-3 fw-bolder">การสั่งซื้อ Zii Token โดยใช้เงินสด ผ่านตัวแทนจำหน่าย “Zii Token”
          </h3>
          <ol start={1} className="fs-5 mb-0">
          <li className="mb-4">ท่านสามารถกรอกแบบ Form การสั่งซื้อได้ที่ <br />
              <span className="me-2 fw-bolder">Website:</span>
              <a href="https://zii.wisden.io/private-sale" className="text-hover-dark fw-bold">zii.wisden.io/private-sale</a>
          </li>
          <li className="mb-4">
              เจ้าหน้าที่จะติดต่อกลับหาท่านเพื่อยืนยัน จำนวนเงิน และ จำนวน Token
          </li>
          <li className="mb-4">
              นักลงทุน โอนเงินเข้าได้ที่: <br />
              <span className="me-2 fw-bolder">บัญชีธนาคาร:</span>ไทยพาณิชย์ <br />
              <span className="me-2 fw-bolder">ชื่อบัญชี:</span>นางพรลภัส ณ ลำพูน <br />
              <span className="me-2 fw-bolder">เลขที่บัญชี:</span>328-2-41116-4
          </li>
          <li className="mb-4">เมื่อท่านได้ทำการโอนเงินเข้าบัญชีดังกล่าว สำเร็จแล้ว
              ให้ท่านนำส่งหลักฐานการโอนเงิน มาที่
              <span className="me-2 fw-bolder">E-mail:</span>
              <a href="mailto:ziitoken@wisden.io" className="text-hover-dark fw-bold">ziitoken@wisden.io</a>
              เพื่อรับเหรียญ “Zii Token”
          </li>
          <li>
              บริษัทจะทำการส่งเอกสารยืนยันสิทธิ์การรับเหรียญ Zii Token ให้แก่ลูกค้า ภายในระยะเวลา 24 ชั่วโมง
          </li>
          </ol>
        </div>
        <div className="separator my-10" />
        <div className="w-100">
          <h3 className="fs-3 mb-3 fw-bolder">การสั่งซื้อ Zii Token โดยการใช้ เงินสกุลดิจิทัล</h3>
          <ol start={1} className="fs-5 mb-0">
          <li className="mb-4">ท่านสามารถกรอกแบบ Form การสั่งซื้อได้ที่ <br />
              <span className="me-2 fw-bolder">Website:</span>
              <a href="https://zii.wisden.io/private-sale" className="text-hover-dark fw-bold">zii.wisden.io/private-sale</a>
          </li>
          <li className="mb-4">
              เจ้าหน้าที่จะติดต่อกลับหาท่านเพื่อยืนยัน จำนวนเงิน และ จำนวน Token
          </li>
          <li className="mb-4">
              นักลงทุน โอนเงินเข้าได้ที่: <br />
              <span className="me-2 fw-bolder">Wallet address: </span>
              <div className="text-word-wrap">0x70a9033E21B9D700c5E64288fa3C11CD0BADc2a7</div>
          </li>
          <li>
              บริษัทจะทำการส่งเอกสารยืนยันสิทธิ์การรับเหรียญ Zii Token ให้แก่ลูกค้า ภายในระยะเวลา 24 ชั่วโมง
          </li>
          </ol>
        </div>
      </div>
      <div className="w-100 w-lg-600px bg-body rounded shadow-sm p-8 p-lg-15 mx-auto">
        <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_signup_form'
        onSubmit={formik.handleSubmit}
      >
        <div className="text-center mb-10">
          <h1 className="text-dark fs-1 mb-3">Request Form</h1>
          <span className="fs-5">Private Sale</span>
        </div>
        {formik.status && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        )}
        <div className="fv-row mb-5">
          <label className="form-label fs-6 fw-bolder text-dark">อีเมล
              <span className="text-danger">*</span>
          </label>
          <input
            type='email'
            autoComplete='off'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {'is-invalid': formik.touched.email && formik.errors.email},
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        <div className="fv-row mb-5">
          <label className="form-label fs-6 fw-bolder text-dark">ชื่อ
              <span className="text-danger">*</span>
          </label>
          <input
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('firstname')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.firstname && formik.errors.firstname,
              },
              {
                'is-valid': formik.touched.firstname && !formik.errors.firstname,
              }
            )}
          />
          {formik.touched.firstname && formik.errors.firstname && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.firstname}</span>
              </div>
            </div>
          )}
        </div>
        <div className="fv-row mb-5">
        <label className="form-label fs-6 fw-bolder text-dark">นามสกุล
            <span className="text-danger">*</span>
        </label>
        <input
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('lastname')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.lastname && formik.errors.lastname,
            },
            {
              'is-valid': formik.touched.lastname && !formik.errors.lastname,
            }
          )}
        />
        {formik.touched.lastname && formik.errors.lastname && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.lastname}</span>
            </div>
          </div>
        )}
        </div>
        <div className="fv-row mb-5">
        <label className="form-label fs-6 fw-bolder text-dark">เบอร์โทรศัพท์ <i>(ไม่บังคับกรอก)</i></label>
        <input
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('mobile')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.mobile && formik.errors.mobile,
            },
            {
              'is-valid': formik.touched.mobile && !formik.errors.mobile,
            }
          )}
        />
        {formik.touched.mobile && formik.errors.mobile && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.mobile}</span>
            </div>
          </div>
        )}
        </div>
        <div className="fv-row mb-5">
        <label className="form-label fs-6 fw-bolder text-dark">ยอมรับความเสี่ยงได้
            <span className="text-danger">*</span>
        </label>
        <div className="form-check form-check-custom form-check-solid">
          <input
            className='form-check-input'
            type='checkbox'
            id='kt_login_toc_agree'
            {...formik.getFieldProps('acceptTerms')}
          />
          <label className="form-check-label" htmlFor="flexCheckDefault">
          ยอมรับได้
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.acceptTerms}</span>
              </div>
            </div>
          )}
        </div>
        </div>
        <div className="fv-row mb-5">
        <label className="form-label fs-6 fw-bolder text-dark">BEP20 Wallet Address
            <span className="text-danger">*</span>
        </label>
        <input
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('wallet')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.wallet && formik.errors.wallet,
            },
            {
              'is-valid': formik.touched.wallet && !formik.errors.wallet,
            }
          )}
        />
        {formik.touched.wallet && formik.errors.wallet && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.wallet}</span>
            </div>
          </div>
        )}
        </div>
        <div className="fv-row mb-5">
        <label className="form-label fs-6 fw-bolder text-dark">จำนวน Token ที่ท่านต้องการซื้อ
            <span className="text-danger">*</span>
        </label>
        <input
          type='number'
          autoComplete='off'
          {...formik.getFieldProps('amount')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.amount && formik.errors.amount,
            },
            {
              'is-valid': formik.touched.amount && !formik.errors.amount,
            }
          )}
        />
        {formik.touched.amount && formik.errors.amount && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.amount}</span>
            </div>
          </div>
        )}
        </div>
        <div className="separator my-10" />
        <div className="text-center">
          <button
            type='submit'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-primary w-100'
            disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
          >
            {!loading && <span className='indicator-label'>Submit</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
       </form>
      </div>
      
    </div>
  )
}