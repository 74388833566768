import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {Home} from "../pages/Home"
import { Thankyou } from '../pages/Thankyou'

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='/private-sale/auth/*' element={<Navigate to='/private-sale' />} />
        {/* Pages */}
        <Route path='private-sale' element={<Home />} />
        <Route path='thankyou' element={<Thankyou />} />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/private-sale' />} />
      </Route>
    </Routes>
  )
}

export {PrivateRoutes}
