/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Route, Routes} from 'react-router-dom'
import {Login} from './components/Login'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

const AuthLayout = () => {
  useEffect(() => {
    document.body.classList.add('bg-dark')
    return () => {
      document.body.classList.remove('bg-dark')
    }
  }, [])

  return (
    <div className="d-flex flex-column flex-column-fluid bgi-position-y-center position-x-center bgi-no-repeat bgi-size-cover bgi-attachment-fixed" style={{backgroundImage: 'url(/media/img/section-bg.png)'}}>
      <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
        <div className="mb-12">
          <img alt="Logo" src={toAbsoluteUrl('/media/coins/zii-token-logo.svg')} className="h-45px logo" />
        </div>
        <div className="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
          <Outlet />
        </div>
      </div>
    </div>
  )
}

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export {AuthPage}
