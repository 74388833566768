import {toAbsoluteUrl} from '../../_metronic/helpers'
import {useAuth} from '../modules/auth'

export function Thankyou() {
  const {logout} = useAuth()
  return (
    <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
      <div className="mb-12">
        <img alt="Logo" src={toAbsoluteUrl('/media/coins/zii-token-logo.svg')} className="h-45px logo" />
      </div>
      <div className="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
        <div className="text-center mb-10">
          <h1 className="text-dark fs-1 mb-10">คำสั่งซื้อของท่าน <span className="text-success">สำเร็จแล้ว</span></h1>
          <div className="fs-4">
            เจ้าหน้าที่จะติดต่อกลับหาท่าน <br />
            เพื่อยืนยัน จำนวนเงิน และ จำนวน Token อีกครั้ง
          </div>
        </div>
        <div className="text-center">
          <button onClick={logout}
            type='button'
            className='btn btn-lg btn-primary w-100'
          >
            สั่งซื้อเพิ่มเติม
          </button>
        </div>
      </div>
    </div>
  )
}